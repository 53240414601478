#app {
    height: 100%;
}

html,
body {
    position: relative;
    height: 100%;
}

body {
    background: #eee;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #000;
    margin: 0;
    padding: 0;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;

    transition: transform 2s ease-in-out; 
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mySwiper {
    position: relative;
    height: 700px;
    overflow: hidden;
}

.mySwiper .swiper-slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}

.mySwiper img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.First-slider {
    display: flex;
    flex-direction: row;
}

.mySwiper p {
    position: relative;
    z-index: 2;
    font-size: 2rem;
    text-align: center;
    width: 600px;
    animation: mini 5s both; 
    opacity: 0; 
    font-weight: bold;
}

@keyframes mini {
    0% {
        top: -200px;
        opacity: 0; 
    }
    100% {
        top: 0px; 
        opacity: 1; 
    }
}

.mySwiper a {
    position: relative;
    z-index: 2;
    color: white;
    text-decoration: none;
    margin-top: 4px;
}

.swiper {
    transition: transform 2s ease-in-out; 
    animation-fill-mode: both;
}


.text-animation {
    position: absolute;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    color: white; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); 
    animation-name: heading;
    opacity: 1; 
    transition: opacity 0.5s ease-in-out; 
    animation-fill-mode: forwards;
}

@keyframes heading {
    0% {
      top: -200px;
    } 
  
    100% {
      top: 30px;
    }
  }