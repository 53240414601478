.service {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 650px;
}

.service-right h1 {
    font-weight: bolder;
    color: rgba(164,136,46,0.7);
    font-size: xx-large;
    margin-top: 100px;
    width: 450px;
}

.service-right p {
    width: 450px;
    color: white;
    font-size: large;
    letter-spacing: 1px;
}

.service-provide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3)),
        url(../assets/serviseP.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 650px;
    width: 100%;
    position: relative;
    background-attachment: fixed;
    height: 650px; 
}
.service-right{
    display: flex;
    flex-direction: column;
    justify-content: start;
}

@media (max-width: 768px) {
    .service {
        flex-direction: column; 
        align-items: center;
    }

    .service-right h1 {
        margin-top: 40px; 
        font-size: large;
    }

    .service-right p {
        font-size: medium; 
        padding: 0 20px; 
    }

    .service-provide {
        height: 400px; 
    }
}

@media (max-width: 480px) {
    .service-right h1 {
        font-size: medium; 
    }

    .service-right p {
        font-size: small; 
    }

    .service-provide {
        height: 300px; 
    }
}