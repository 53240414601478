.contact{
    display: flex;
    justify-content: center;
    /* background-color: rgb(18, 103, 93); */
    padding: 10px;
    padding-bottom: 0px;
    color: black;
   height: 500px;
   flex-direction: row;
   gap: 20px;
    
}
.contact form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 15px;
    background-color: white;
    width: 60%;
    height: 400px;
    box-shadow: 1px 1px 8px rgba(0,0,0,0.065);
    border-radius: 6px;
    width: 300px;
    margin-top: 30px;
    
}
.contact form input{
    border: none;
    border-bottom: 1px solid black;
    background-color: white;
    height: 50px;
    border-radius: 6px;
    width: 200px;
}
.contact form textarea{
    border: none;
    border-bottom: 1px solid black;
    background-color: white;
    height: 100px;
    border-radius: 6px;
    width: 250px;
    color: rgba(0,0,0,0.5);
}
.contact button{
    padding: 21px 4px;
    color: white;
    background-color: orange;
    border-radius:20px;
}
.contactss{
    display: flex;
    flex-direction: row;
    height: 450px;
    box-shadow: 1px 1px ;
    margin-bottom: 20px;
    gap: 20px;
    justify-content: center;
    /* align-items: center; */
    margin-top: 40px;
}
.cont-upper{
    display: flex;
    flex-direction: column;
    /* justify-content: start;
    align-items: center; */
    margin-bottom: -10px;
    text-align: center;
    gap: 20px;
}
.cont-upper .fa{
    color: rgba(0, 128, 0, 0.7);
    font-size: xx-large;
}
.cont-upper h3{
    color: rgba(0, 0, 0, 0.5);
}
.cont-upper ul{
   display: flex;
   align-items: center;
   flex-direction: column;
   text-align: center;
   justify-content: center;
}
.cont-upper ol{
    display: flex;
    margin-left:40px;
    width: 300px;
}

.cont-upper ul li{
    display: flex;
   text-align: center;
    color: rgba(0, 128, 0, 0.7);
}
.cont-upper ul a{
   text-decoration: none;
    color: rgba(0, 128, 0, 0.7);
    margin-top: 5px;
}
.cont-upper ul li p{
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.5);
}
.cont-upper ol li{
  
    color: rgba(0, 128, 0, 0.7);
}

@media (max-width: 768px) {
    .contactss {
        flex-direction: column; 
        height: auto; 
        box-shadow: none;
    }
    .info-list a {
        font-size: 0.9em; 
        text-align: center;
        width: 400px;
    }
    .cont-upper {
        text-align: center;
    }

    .cont-upper ol {
        width: 100%; 
        margin-left: 0; 
    }

    .cont-upper h3{
        text-align: center;
    }
}

@media (max-width: 480) {
    .contactss {
        flex-direction: column; 
        height: auto; 
        box-shadow: none;
    }
    .info-list a {
        width: 400px;
        font-size: 0.9em; 
        text-align: center;
    }

    .cont-upper {
        text-align: center;
    }
    .cont-upper h3{
        text-align: center;
    }

    .cont-upper ol {
        width: 100%; 
        margin-left: 0; 
    }
    .cont-upper h3{
        text-align: center;
    }
    .cont-upper a{
        width: 300px;
    }
}
