.about {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* background-color: rgba(164, 136,46, 0.7); */
    height: 500px;
    /* margin-bottom: 40px; */
    margin-top: 60px;

}

.vision {
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: space-around;
    /* margin-bottom: 100px; */
    background: linear-gradient(to right, rgba(164, 136, 46, 0.8), rgba(164, 136, 46, 0));
}

.linkages {
    z-index: 1;
}

.mission {
    display: flex;
    gap: 50px;
    flex-direction: column;
    justify-content: space-around;
    background: linear-gradient(to right, rgba(164, 136, 46, 0.8), rgba(164, 136, 46, 0));
}

.vision h1 {
    display: flex;
    color: rgba(0, 128, 0, 0.7);
    justify-content: center;
    /* align-items: center; */
}

.mission h1 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    color: rgba(0, 128, 0, 0.7);
    margin-top: 0px;

}

ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 400px;
    font-weight: bold;
    color: rgb(8, 8, 8);

}

.news-upper {
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    /* align-items: center; */
    position: relative;
    width: 100%;
    height: 550px;
    overflow: hidden;
}

.news-upper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-la {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    /* object-fit: contain; */
}

.news-la h3 {
    font-size: 50px;
    line-height: 1.3;
    position: relative;
    animation: heading;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    color: white;
}

@keyframes heading {
    0% {
        /* top: -200px; */
        left: -400px;
    }

    100% {
        /* top: 30px; */
        left: 10px;
    }
}

.news-upper .news-la a {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: larger;
    text-decoration: none;

}

.news-upper .news-la p {
    color: white;
    justify-content: center;
    align-items: center;
    font-size: larger;
}


.history {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* margin: 60px; */
    position: relative;
    gap: 50px;

}

.adHis {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.history img {
    transition: opacity 0.5s ease-in-out;

}

.side-history {

    width: 500px;
    /* margin: 40px; */

}

.side-history .botton-history {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 50px;
}

.side-history .botton-history p {
    font-size: larger;
    color: rgba(0, 128, 0, 0.7);
}

.side-history .botton-history p icon {
    font-size: larger;
}

.side-history h1 {
    color: rgba(0, 128, 0, 0.7);
}

.fade-image {
    width: 400px;
    height: auto;
    transition: opacity 0.5s ease-in-out;
}

.fade-in {
    opacity: 0.7;
}

.fade-out {
    opacity: 1;
}

.our-value {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px;
}

.our-value h1 {
    color: rgba(164, 136, 46, 0.8);
}

.our-value p {
    text-align: center;
    width: 450px;
}

.our-partnr {
    display: flex;
    position: relative;
    width: 90%;
    margin: 50px;
    overflow: hidden;
    /* height: 300px; */
}

.part-nr {
    width: 100px;
    margin: 0 10px;
    border-radius: 50%;
    animation: move-left 30s linear infinite;
    /* margin: 50px; */

}

@keyframes move-left {
    0% {
        transform: translateX(100vw);
    }

    100% {
        transform: translateX(-200%);
    }
}

.part-nr:nth-child(1) {
    animation-delay: 0s;
}

.part-nr:nth-child(2) {
    animation-delay: 2s;
}

.part-nr:nth-child(3) {
    animation-delay: 4s;
}

.part-nr:nth-child(4) {
    animation-delay: 6s;
}

.part-nr:nth-child(5) {
    animation-delay: 8s;
}

.part-nr:nth-child(6) {
    animation-delay: 10s;
}

.part-nr:nth-child(7) {
    animation-delay: 12s;
}

.part-nr:nth-child(8) {
    animation-delay: 14s;
}

.part-nr:nth-child(9) {
    animation-delay: 16s;
}



.up-partner {
    margin-top: 40px;
}

.up-partner h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(164, 136, 46, 0.8);
    margin-top: 100px;
}

@media (max-width: 768px) {
    .about {
        flex-direction: column;
        align-items: center;
    }

    .adHis {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .emp-pics {
        width: 400px;
        height: 500px;
    }

    .vision,
    .mission {
        width: 90%;
        padding: 10px;
    }

    ul {
        width: 100%;
    }

    .emp-one {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .history {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }

    .side-history {
        width: 90%;
    }

    .fade-image {
        width: 100%;
    }

    .our-value {
        margin-top: 400px;
    }

    .our-value p {

        width: 90%;
    }

    .emp-pics Image {
        width: 400px;
    }
}

@media (max-width: 480px) {
    .news-la h3 {
        font-size: 30px;
    }

    .news-upper {
        height: 300px;
    }

    .adHis {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .emp-one {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .emp-pics Image {
        width: 400px;
    }

    .up-partner h1 {
        font-size: 24px;
        margin-top: 20px;
    }

    .emp-pics {
        width: 400px;
        height: 500px;
    }

    .our-value {
        margin-top: 300px;
    }
}

.join-us {
    width: 100%;
    height: 200px;
    background-color: rgba(0, 128, 0, 0.7);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}

.join-us p {
    width: 800px;
    color: white;
    font-weight: bolder;
}

@keyframes appear {
    from {
        opacity: 0;
        scale: 0.5;
    }

    to {
        opacity: 1;
        scale: 1;
    }

}

@keyframes motive {
    from {
        opacity: 0;
        scale: 0;
    }

    to {
        opacity: 1;
        scale: 1;
    }

}

.about {
    animation: appear 8s linear;
    animation-timeline: view();
    animation-range: entry 0%;
}

.join-us {
    animation: motive 8s linear;
    animation-timeline: view();
    animation-range: entry 0%;
}

.our-value {
    animation: appear 8s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 20%;
}

.side-history {
    animation: appear 5s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 20%;
}

.home-images {
    animation: appear 8s linear;
    animation-timeline: view();
    animation-range: entry 0% cover 20%;
}

.meet-employee {
    display: flex;
    flex-direction: column;
}

.meet-employee h1 {
    margin-bottom: 30px;
}

.emp-one {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 70px;
}

.emp-two {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.emp-pics {
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: rgb(240, 243, 246);
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.emp-info {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.emp-info p {
    width: 270px;
}

.emp-pics img {
    width: 200px;
    height: 200px;
}

.emp-icons {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    margin-top: 50px;

}

@keyframes motive {
    from {
        opacity: 0;
        scale: 0;
    }

    to {
        opacity: 1;
        scale: 1;
    }

}

.emp-pics {
    animation: appear 8s linear;
    animation-timeline: view();
    animation-range: entry 0%;
}

@media (max-width: 768px) {
    .emp-one {
        flex-direction: column;
        align-items: center;
        /* gap: 10px; */
    }

    .emp-two {
        /* gap: 10px;  */
    }

    .emp-pics {
        flex-direction: column;
        align-items: center;
    }

    .emp-info p {
        max-width: 90%;
    }

    .emp-pics img {
        max-width: 500px;
    }

    .emp-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
    }
}

@media (max-width: 480px) {
    .emp-one {
        gap: 10px;
    }

    .emp-two {
        gap: 10px;
    }

    .emp-pics img {
        max-width: 400px;
    }

    .emp-icons {
        margin-top: 30px;
    }

    .emp-info {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 20px;
    }
}