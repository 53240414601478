.header {
    /* position: fixed; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    /* background-color: #fff; */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    color: black;
    z-index: 1000;
    width: 100%;
}


.left-header img {
    height: 50px;
}

.middle-header {
    position: relative;
}

.toggle-icon {
    font-size: 24px;
    cursor: pointer;
    display: none; 
}

.nav-links {
    display: flex;
    gap: 20px; 
   
}
.nav-links a{
    text-decoration: none;
    color: black;
}

.nav-links a:hover{
    color: rgba(170, 137, 27, 0.9);
    font-size: large;
}
.right-header a{
    text-decoration: none;
    color: black;  
}

.right-header a:hover{
    color: rgba(164,136,46,0.9);
    font-size: large;
}
.right-header {
    display: flex;
    gap: 20px;
}

@media (max-width: 600px) {
    .nav-links {
        display: none; 
        flex-direction: column;
        position: absolute; 
        top: 50px; 
        left: 0;
        right: 0;
        border: 1px solid #e0e0e0; 
        z-index: 1; 
        color: white;
        border: none;
    }
    a{
        color: rgba(0,128,0,0.7);
        width: 100px;
    }

    .nav-links a{
        color: white;
    }

    .nav-links.show {
        display: flex; 
    }

    .toggle-icon {
        display: block;
    }
}

.header-scroll {
    position: fixed;
    background-color: transparent;
    transition: background-color 0.3s ease;
    z-index: 1000;
    width: 100%;
    color: white;
  }
  
  .header-scroll.scrolled {
    background-color: white;
    color:rgba(7, 225, 7, 0.9);
  }