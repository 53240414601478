.loader {
    height: 100px;              
    width: 100px;              
    background-color: rgba(0, 128, 0, 0.8);
    position: fixed;             
    top: 42.5%;                      
    left: 45.5%;                     
    z-index: 9999;              
    display: flex;              
    justify-content: center;     
    align-items: center;         
}

.spinner {
    border: 8px solid rgba(164, 136, 46, 0.9); 
    width: auto;
    height: auto;
    border-top: 8px solid rgba(0, 128, 0, 0.7); 
    border-radius: 50%;
    animation: spin 3s cubic-bezier(0.5, 0, 0.5, 1) infinite; 
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}