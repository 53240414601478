
  .vacancy-list {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .vacancy-card {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    background-color: #f9f9f9;
  }
  
  .vacancy-card h2 {
    margin: 0 0 10px;
  }

  .news-upper {
    position: relative;
    text-align: center;
}

.header-image {
    width: 100%;
    height: auto;
    max-height: 300px;
    object-fit: cover;
}

.news-la {
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
}

.vacancy-title {
    text-align: center;
    margin: 20px 0;
    font-size: 2em;
    color: #333;
}

.vacancy-detail {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
}
.vacancy-detail h2{
  margin-bottom: 10px;
  color: rgba(0,128,0,0.7);
}

.vacancy-detail p{
  margin-bottom: 10px;
}

.vacancy-detail button{
  background-color: rgba(0,128,0,0.7);
  width: 200px;
}

.vacancy-detail button:hover{
  background-color: rgba(164,136,46,0.9);
  width: 200px;
}
.vacancy-info {
    line-height: 1.6;
    margin-bottom: 20px;
}

.apply-button {
    display: inline-block;
    padding: 10px 15px;
    font-size: 16px;
    color: white;
    background-color: rgba(0,128,0,0.7);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.apply-button:hover {
    background-color: rgba(164,136,46,0.9);
}

.vacancy-not-found {
    text-align: center;
    font-size: 1.5em;
    color: #ff0000;
    margin-top: 20px;
}
  
  @media (max-width: 600px) {
    .vacancy-list {
      padding: 10px;
    }
    .vacancy-card {
      padding: 10px;
    }
  }

  .vacancy-card h2{
    color: rgba(0,128,0,0.7);
    margin-bottom: 10px;
  }

  .vacancy-list{
    display: flex;
    /* flex-direction: column; */
  }
  h1{
    color: rgba(0,128,0,0.7);
    padding: 20px;
  }
  input {
    width: 300px;
    margin-bottom: 10px;

  }

  @media(max-width:780px){
     .vacancy-list{
      flex-direction: column;
      gap: 20px;
     }   
  }
  @media(max-width:480px){
    .vacancy-list{
     flex-direction: column;
     gap: 20px;
    }   
 }