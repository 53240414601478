.partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.part-1 h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 128, 0, 0.7);
}

.part-1 p {
    width: 500px;
    text-align: center;
}

.part-2 {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 1536px;
    height: 200px;
    border: 1px solid white;
    margin: auto;
    position: relative;
    overflow: hidden;
    mask-image: linear-gradient(to right,
            rgba(0, 0, 0),
            rgba(0, 0, 1) 20%,
            rgba(0, 0, 1) 80%,
            rgba(0, 0, 0));
}

@keyframes scrolleft {
    to {
        left: -200px;
    }
}

.ima {
    display: flex;
    gap: 10px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    position: absolute;
    animation-name: scrolleft;
    animation-duration: 20s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transform: rotate(360deg);
}

.ima.part-21,
.ima.part-22 {
    left: 10%;
    animation-delay: 2s;
}

.part-23 {
    left: 30%;
    animation-delay: 2s;
}

.part-24 {
    left: 40%;
    animation-delay: 2s;
}

.part-25 {
    left: 50%;
    animation-delay: 2s;
}

.part-26 {
    left: 60%;
    animation-delay: 2s;
}

.part-27 {
    left: 80%;
    animation-delay: 2s;
}

.part-28 {
    left: 90%;
    animation-delay: 2s;
}

.part-29 {
    left: 100%;
    animation-delay: 2s;
}

.middle-partners {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow-y: auto;
    max-height: 600px;
    border: 1px solid #ccc;
    position: relative; /* Positioning context for the selected image */
}

.image-container {
    display: flex;
    flex-wrap: wrap;
    height: 300px;
}

.part-mr {
    flex: 1 1 200px;
    margin: 10px;
    background-color: #f0f0f0;
    border: 1px solid #aaa;
    box-sizing: border-box;
    transition: transform 0.3s ease; 
    cursor: pointer;
    filter: blur(0);
}

.part-mr:hover {
    transform: scale(1.1); 
}
.selected-image {
    position: fixed;  
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    z-index: 10; 
    border: 5px solid #fff; 
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5); 
    max-width: 90vw; 
    max-height: 100vh; 
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 10px; 
}
.selected-image img {
    width: 90%; 
    height: auto; 
    max-height: 100%; 
    object-fit: contain; 
}

.middle-partners img {
    filter: blur(1px);
}

.selected-image img {
    filter: none; 
}

@media (max-width: 768px) {
    .partners {
        padding: 10px;
        gap: 15px;
    }

    .part-1 h1 {
        font-size: 1.5rem;
    }

    .part-1 p {
        width: 300px;
        font-size: 14px;
    }

    .part-2 {
        height: 150px;
    }

    .ima {
        width: 100px;
        height: 100px;
    }
    .image-container{
        width: 400px;
    }
}

@media (max-width: 480px) {
    .partners {
        gap: 10px;
    }

    .part-1 h1 {
        font-size: 1.2rem;
    }

    .part-1 p {
        width: 300px;
        font-size: 12px;
    }

    .part-2 {
        height: 120px;
    }

    .ima {
        width: 80px;
        height: 80px;
    }
    .image-container{
        width: 400px;
    }
}