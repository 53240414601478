.chatbot-container {
    position: fixed; 
    bottom: 20px; 
    right: 20px; 
    z-index: 1000; 
}

.chatbox {
    width: 300px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: height 0.3s ease; 
}

.closed {
    height: 40px; 
    width: 100px;
}

.open {
    height: auto; 
}

.messages {
    height: 400px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    background-color: rgba(164, 136, 46, 0.2);
}

.userMessage {
    text-align: right;
    padding: 5px;
    background: #d1ffd6;
    border-radius: 5px;
    margin: 5px 0;
}

.botMessage {
    text-align: left;
    padding: 5px;
    background: #f1f1f1;
    border-radius: 5px;
    margin: 5px 0;
    width: 200px;
}

.inputArea {
    display: flex;
}

.inputField {
    flex: 1;
    padding: 10px;
    border: none;
    border-top: 1px solid #ddd;
}

.sendButton {
    padding: 10px;
    border: none;
    background: #28a745;
    color: white;
    cursor: pointer;
}

.toggleButton {
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    position: absolute; 
    top: -10px; 
    right: 10px; 
    width: 100px;
}

.icon-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.icon {
    text-decoration: none; 
    color: #007bff; 
    font-size: 24px;
    margin: 0 5px; 
}

.icon:hover {
    color: green; 
}
.icon {
    color: black; 
    text-decoration: none; 
}