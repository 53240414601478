.card-list {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    background-image: url('../assets/ssss.jpg');
    background-attachment: fixed;
    background-size: cover;
    /* background-repeat: no-repeat; */
    height: 700px;
    flex-direction: column;
    gap: 100px;
}

.upper-cl {
    background: rgba(7, 7, 197, 0.7);
    padding: 20px 60px;

    color: white;
}

.loweer-cl .colsr {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.colsr img {
    width: 200px;
    height: 200px;
}

.upper-cl h1 {
    color: white;
}

@keyframes appear {
    from{
       opacity:0;
       transform: scale(0.2) translateY(20px);
    }
    to{
       opacity: 1;
       transform: scale(1) translateY(0);
    }
    
}
.colsr, .upper-cl{
   animation: appear 5s linear;
   animation-timeline:view();
   animation-range: entry 0%;
   animation-fill-mode: forwards; 
}


@media (max-width: 768px) {
    .card-list {
        height: auto;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upper-cl {
        padding: 20px 30px;
    }

    .lower-cl {
        gap: 50px;
    }

    .lower-cl .colsr {
        gap: 50px;
    }

    .colsr img {
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 480px) {
    .card-list {
        display: flex;
        justify-content: center;
        align-items: center;
        gap:10px;

    }
    .upper-cl {
        padding: 20px 20px;
    }

    .lower-cl {
        gap: 50px;
    }

    .lower-cl .colsr {
        display: flex;
        justify-content: center;
        align-items: center;
        gap:10px;
        flex-direction: column;
        gap: 30px;
    }

    .colsr img {
        width: 100px;
        height: 100px;
    }

    .colsr {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 768px) and (max-width: 834px) {
    .card-list {
        height: 300px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upper-cl {
        padding: 20px 30px;
    }

    .lower-cl {
        gap: 50px;
    }

    .lower-cl .colsr {
        gap: 50px;
    }

    .colsr img {
        width: 150px;
        height: 150px;
    }
}

/* Landscape tablets */
@media (min-width: 1024px) and (max-width: 1366px) {
       .card-list {
        height: 300px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .upper-cl {
        padding: 20px 30px;
    }

    .lower-cl {
        gap: 50px;
    }

    .lower-cl .colsr {
        gap: 50px;
    }

    .colsr img {
        width: 150px;
        height: 150px;
    }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px){
    .card-list{
        height: 550px;
    }
}

