.news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
}

.haey .latest-news {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 20px;

}

.neews h2 {
    color: rgba(0, 128, 0, 0.9);
}

.upper-news {
    display: flex;
    flex-direction: column;
    

}

.haey {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.haey p {
    width: 500px;
}

.haey h1 {
    color: rgba(0, 128, 0, 0.7);
}

.news-upper h2 {
    line-height: 1.3;
    position: relative;
    animation: heading;
    animation-duration: 3s;
    animation-fill-mode: forwards;
    color: rgba(164, 136, 46, 0.7);
}


.news-container {
    display: flex;
}

.main-news {
    flex: 3;
    padding: 20px;
}

.main-news p {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    text-align: center;
}

.minimized-news {
    flex: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-left: 1px solid #ccc;
}

.main-news {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 400px;
}

.latest-news {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 800px;
}

.neews {
    margin-bottom: 20px;
   
}
.neews h2 p{
    display: flex;
    text-align: center;
}

.neews img {
    width: 600px;
    height: 400px;
    
}

.neews button{
    display: flex;
    justify-content: center;
    width: 150px;
    font-size: medium;

}
.neews button span {
    margin-left: 5px; 
}
.neews a{
    display: flex;
    justify-content: end;
    text-decoration: none;
}

.neews.minimized {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.neews.minimized img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.neews.minimized h2 {
    font-size: small;
    width: 200px;
    margin: 0;
}

.news-container {
    display: flex;
    justify-content: space-between; 
    align-items: start;
    gap: 20px; 
    padding: 20px;
}

.main-news {
    width: 400px;
    background-color: #ffffff; 
    border-radius: 8px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.minimized-news {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: #ffffff; 
    border-radius: 8px; 
    padding: 20px; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.latest-news,
.minimized-news {
    margin-top: 20px; 
}
.neews {
    margin-bottom: 20px; 
}

@media (max-width: 768px) {
    .news-container {
        flex-direction: column; 
    }

    .latest-news {
        width: 100%;
        margin-bottom: 20px; 
    }

    .minimized-news {
        border-left: none; 
        width: 90%;
    }
    .neews img {
        width: 400px;
        
    }
    .neews.minimized button{
        width: 150px;
    }
}

@media (max-width: 480px) {
    .main-news p {
        font-size: 0.9em; 
    }

    .neews.minimized h2 {
        font-size: x-small;
    }
    .neews img {
        width: 400px;  
    }
}
.neews.minimized button{
    width: 150px;
}
.neews.minimized{
    display: flex;
    justify-content: start;
    align-items: start;
    width: 90%;
}
.minimized-news{
    display: flex;
    justify-content: start;
    align-items: start;
    max-width: 90%;
}
.neews.minimized a{
    width: 150px;
}
@media (max-width: 768px) {
    .haey .latest-news {
        flex-direction: column;
        align-items: center;
    }
    .news-container.minimized-news{
        width: 300px;
    }

    .neews {
        width: 90%;
        max-width: 90%;
    }

    .latest-news .neews p {
        max-width: 90%;
    }

    .neews button {
        padding: 5px;
        width: 200px;
    }
    .news-container .main-news p{
        max-width: 400px;
        align-items: center;
    }
    .minimized-news{
        width: 400px;
    }
}

@media (max-width: 480px) {
    .minimized-news{
        width: 400px;
    }
    .news-container .main-news p{
        max-width: 400px;
        align-items: center;
    }
    .neews {
        margin: 2px;
    }

    .haey h1 {
        font-size: 1.5rem;
    }

    .haey p { 
        font-size: 14px;
    }

    .news-upper h2 {
        font-size: 1.8rem;
    }

    .neews button {
        padding: 5px;
        width: 200px;
    }
   
}

