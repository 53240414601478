.home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    background-color:rgba(7, 7, 197,0.7);
    margin: 0px;
    padding-top: -10px;
    color: white;
}

@keyframes appear {
     from{
        opacity:0;
        scale:0.5 ;
     }
     to{
        opacity: 1;
        scale: 1;
     }
     
}
.bttt-1{
    animation: appear 5s linear;
    animation-timeline:view();
    animation-range: entry 0% cover 40%;
}

.mdd1{
    animation: appear 5s linear;
    animation-timeline:view();
    animation-range: entry 0% ;
}


/* .vision-reality{
    animation: appear 5s linear;
    animation-timeline:view();
    animation-range: entry 0% ;
} */

@keyframes motive {
    from{
       opacity:0;
       scale:0.2 ;
    }
    to{
       opacity: 1;
       scale: 1;
    }
    
}
.last-vacancy {
   animation: motive 5s linear;
   animation-timeline:view();
   animation-range: entry 0% ;
}
.last-vacancy .lower-news button{
    animation: motive 5s linear;
    animation-timeline:view();
    animation-range: entry 0% ;
}

.home p {
    width: 650px;

}

.home h1 {
    color: white;
    width: 650px;
    align-items: justify;
}

.home-images {
    display: flex;
    flex-direction: row;
    gap: 150px;
    margin-top: 50px;
}

.h1 {
    font-size: xx-large;
    color: white;
    font-weight: bolder;
    transition: transform 0.6s ease;
    text-align: center;
}



.h1:hover {
    transform: rotateY(360deg);
    transition: transform 0.6s ease;
}

.home-images img {
    width: 100px;
    height: 100px;
    color: white;
}

.h1 h2 {
    color: white;
    font-size: small;
    text-align: center;
}

.service-we-provide {
    /* display: flex;
    flex-direction: column;
    background-color: antiquewhite; */


}

.pa-card {
    display: flex;
    flex-direction: row;
    gap: 100px;

}

.latest-news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* Changed to 100% for responsiveness */
}

.upper-news {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 80%;
    margin-bottom: 30px;
    position: relative;
    height: 400px;
    overflow: hidden;
}

.lower-news {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
    padding-top: 100px;
    display: flex;
    flex-direction: column;
}

.lower-news a {
    text-decoration: none;
    color: blue;
}

.lower-news h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(7, 7, 97, 0.7);
    padding: 20px 10px;
    width: auto;
    /* Changed to auto for better responsiveness */
    max-width: 250px;
    /* Set a max-width */
}

.upper-news img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lower-news p {
    color: white;
    margin-left: 10px;
}

.latest-news h1 {
    color: rgba(7, 7, 197,0.7);
}

.lower-news a:hover {
    color: blue;
}

.lower-news button {
    margin-top: 60px;
    color: white;
    background-color: rgba(7, 7, 197,0.7);
    padding: 12px 24px;
    border-radius: 10px;
    border-color: white;
    width: 150px;
}

.lower-news button:hover {
    background-color: blue;
    animation-name: button;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

/* Responsive styles */
@media (max-width: 768px) {
    .upper-news {
        flex-direction: column;
        height: auto;
    }

    .lower-news p {
        width: auto;
        margin-left: 0;
    }

    .lower-news h3 {
        width: auto;
        margin-left: 0;
    }

    .latest-news {
        width: 100%;
    }

    .lower-news button {
        width: 100%;
        margin-top: 30px;
    }
}

@media (max-width: 480px) {
    .lower-news h3 {
        font-size: 1.2em;
    }

    .lower-news p {
        font-size: 0.9em;
    }
}

@keyframes button {
    0% {
        background-color: rgba(164, 136, 46, 0.2);
    }

    100% {
        background-color: rgba(164, 136, 46, 0.9);
    }
}


@media (max-width: 768px) {
    .home {
        padding-top: 20px;
    }

    .home-images {
        gap: 10px;
    }

    .home p,
    .home h1,
    .lower-news p {
        width: 95%;
    }

    .latest-news h1 {
        font-size: large;
        width: 90%;
    }

    .lower-news h3 {
        width: 90%;
    }

    .pa-card {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .h1 {
        font-size: large;
    }

    .home-images img {
        width: 80px;
        height: 80px;
    }

    .lower-news button {
        width: 100px;
    }
}

.last-vacancy {
    height: 300px;
    width: 100%;
    background: rgba(7, 7, 197,0.7);
    position: relative;
    overflow: hidden;
    border: 1px solid #dee2e6;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.last-vacancy:hover {
    transform: scale(1);
}

.last-vacancy h3 {
    color: white;
    margin-bottom: 10px;
    font-size: 1.5rem;
    animation: fadeIn;
    padding: 10px;
    border-top: 2px solid white;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    width: 170px;
    margin-left: 100px;
    margin-top: 50px;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.last-vacancy p {
    font-size: 1rem;
    color: white;
    margin: 15px 0;
    text-align: center;
    animation: scrollText;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}

.btn1,
.btn2 {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
}

.btn1 {
    background-color: white;
    color: black;
    padding: 12px 12px;
    width: 60px;
    margin: 4px;
    border: 2px solid black;
}

.btn1:hover {
    border: 1px solid white;
    transform: scale(1.05);
    color: white;
    background-color: blue;
    color: white;

}

.btn2 {
    border: 2px solid white;
    color: black;
    margin-top: 10px;
    padding: 12px 24px;
    width: 250px;
    margin-left: 650px;
    margin-top: 20px;
    background-color: white;
    border-radius: 50px;
    animation: heading;
    animation-duration: 3s;
    animation-fill-mode: forwards;
}



@keyframes heading {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.btn2:hover {
    background-color: blue;
    transform: scale(1.05);
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-2px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scrollText {
    from {
        transform: translatex(700px);
    }

    to {
        transform: translatex(10px);
    }
}


.vision-reality {
    height: 800px;
    width: 100%;
}

.vision-reality {
    text-align: center;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
    animation: fadeIn 1s ease-in-out;
}

.vision-reality h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    animation: slideIn 1s ease forwards;
    color: white;
    padding-top: 30px;
}

.vision-reality {
    text-align: center;
    background: none;
    box-shadow: none;  
}

.vision-reality h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    animation: slideIn 1s ease forwards;
    color: rgba(7, 7, 197,0.7);
    padding-top: 30px;
}

.vision-reality p {
    font-size: 1.1rem;
    margin-bottom: 40px;
    animation: fadeInUp 1s ease forwards;
    color: rgba(7, 7, 197,0.7);
}

.middle-v-r p {
    color: white;
}

.upper {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; 
    margin-bottom: 40px;
}

.uppp-ott {
    background: white;
    opacity: 0.2;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    opacity: 0.5;
    animation: fadeIn 1s ease forwards;
    width: 100%; 
    max-width: 450px; 
    height: 150px;
    margin: 10px; 
    color: white;
    
}

.uppp-ott h2,
.bttt-1 h2 {
    color: white;
}

.uppp-ott:nth-child(1) { animation-delay: 1s; }
.uppp-ott:nth-child(2) { animation-delay: 3s; }
.uppp-ott:nth-child(3) { animation-delay: 5s; }

.uppp-ott:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.middle {
    margin: 40px 0;
}

.middle p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    animation: fadeInUp 1s ease forwards;
    color: white;
}

.mddd-1 {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap; 
}

button {
    padding: 12px 24px;
    border: none;
    border-radius: 5px;
    background-color: rgba(7, 7, 197,0.7);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    margin: 4px;
}

button:hover {
    background-color: white;
    color: black;
    transform: scale(1.05);
}

.bottom {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 40px;
}

.bttt-1 {
    background: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transition: background-color 0.3s, transform 0.3s;
    animation: fadeIn 1s ease forwards;
    width: 100%; 
    max-width: 500px; 
    height: 100px;
    margin: 10px; 
}

.bttt-1:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}

.bttt-1:nth-child(1) { animation-delay: 0.2s; }
.bttt-1:nth-child(2) { animation-delay: 0.4s; }

.bttt-1 p {
    width: auto; 
}
.mddd1 {
    width: 250px;
}
.mdd1 {
   width: 200px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .vision-reality {
        height: auto;
        padding: 15px; 
    }

    .vision-reality h3 {
        font-size: 1.8rem; 
    }

    .vision-reality p {
        font-size: 1rem; 
    }

    .uppp-ott {
        width: 100%; 
    }

    .bttt-1 {
        width: 100%; 
    }

    .middle p {
        font-size: 1rem; 
    }
    
}

@media (max-width: 480px) {
    .vision-reality {
        height: auto;
        padding: 15px; 
    }
    .vision-reality h3 {
        font-size: 1.5rem; 
    }

    .vision-reality p {
        font-size: 0.9rem; 
    }

    button {
        padding: 10px 20px;
    }
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
    .vision-reality {
        height: auto;
        padding: 15px; 
    }
    .vision-reality h3 {
        font-size: 1.5rem; 
    }

    .vision-reality p {
        font-size: 0.9rem; 
    }

    button {
        padding: 10px 20px;
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .vision-reality {
        height: auto;
        padding: 15px; 
    }
    .vision-reality h3 {
        font-size: 1.5rem; 
    }

    .vision-reality p {
        font-size: 0.9rem; 
    }

    button {
        padding: 10px 20px;
    }
}
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
    .vision-reality {
        height: auto;
        padding: 15px; 
    }
    .vision-reality h3 {
        font-size: 1.5rem; 
    }

    .vision-reality p {
        font-size: 0.9rem; 
    }

    button {
        padding: 10px 20px;
    }
    .upper.v-r{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@keyframes slideIn {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes appear {
    from{
       opacity:0;
       scale: 0.5;
    }
    to{
       opacity: 1;
       scale: 1;
    }
    
}
.latest-news{
   animation: appear 5s linear;
   animation-timeline: view();
   animation-range: entry 0% cover 20%;
}

@keyframes motive {
    from{
       opacity:0;
       scale:0.2 ;
    }
    to{
       opacity: 1;
       scale: 1;
    }
    
}
/* .vision-reality{
   animation: motive 5s linear;
   animation-timeline:view();
   animation-range: entry 0% ;
} */
@keyframes motive {
    from{
       opacity:0;
       scale:0.2 ;
    }
    to{
       opacity: 1;
       scale: 1;
    }
    
}
.uppp-ott, .mddd-1, .bttt-1{
   animation: motive 5s linear;
   animation-timeline:view();
   animation-range: entry 15% ;
   animation-fill-mode: forwards;
}
.uppp-ott h2{
    color: rgba(7, 7, 197,0.7);
}
.uppp-ott{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.uppp-ott p{
    color: black;
}
.mdd1 p{
    color: black;
}
.mdd1 button{
    background: white;
    color: black;
}
.mddd1 button{
    background: white;
    color: black;
}
.mddd1 button:hover{
    background: black;
    color: white;
}
.mdd1 button:hover{
    background: black;
    color: white;
}
.bttt-1 {
    display: flex;
    flex-direction: column;
    gap: 4px; 
    color: white;
}
.bttt-1 h2{
    /* opacity: 0.5; */
    color: rgba(7, 7, 197,0.7);
}
.bttt-1 p{
    opacity: 0.5;
    color: black;
}
.middle-v-r p{
    color: rgba(7, 7, 197,0.7);
}
@keyframes motive {
    from{
       opacity:0;
       scale:0.2 ;
    }
    to{
       opacity: 1;
       scale: 1;
    }
    
}
.lower-news button{
    animation: motive 5s linear;
    animation-timeline:view();
    animation-range: entry 0% ;
}
