.experiance {
    /* background-color: rgba(164,136,46,0.7); */
    color: black;
}

.lower-exp {
    color: white;
    height: 300px;
    display: flex;
    justify-content: center;
    gap: 100px;
    align-items: center;
    
}

.upper-exp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lower-exp p {
    display: flex;
    font-weight: bold;
    font-size: larger;
    width: 100px;
}

.upper-exp h1 {
    display: flex;
    padding: 30px;
    width: 700px;
    font-weight: bolder;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: rgba(0, 128, 0, 0.7);
    /* background-color:green ; */
}

.upper-exp p {
    display: flex;
    width: 700px;
    padding: 40px;
    padding-bottom: 0;
    color: black;
    padding-top: 2px;
    justify-content: center;
    align-items: center;
    line-height: 30px;
    font-size: large;
    font-weight: medium;
}
.lower-one {
    display: flex;               /* Activate flexbox */
    flex-direction: column;      /* Stack items vertically */
    justify-content: center;     /* Center items vertically */
    align-items: center;         /* Center items horizontally */
    text-align: center;
}

.lower-one img {
    
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transition: transform(0.5s);
}

.lower-one img:hover {
    transform: rotate(0deg);
}



.lower-one p {
    width: 200px;
    /* padding-right: 10px; */
}

.lower-one p{
    color:rgba(0, 128, 0, 0.7);
    /* text-align: center; */
 }

@media (max-width: 768px) {
    .lower-exp {
        flex-direction: column; 
        align-items: center; 
        gap: 20px; 
        padding: 20px; 
        margin: 250px;
        color: rgba(0, 128, 0, 0.7);
    }

    .upper-exp h1 {
        font-size: 24px; 
        padding: 20px; 
        max-width: 90%; 
    }
    
    .upper-exp p {
        font-size: 16px;
        padding: 10px;
        max-width: 90%; 
    }

    .lower-one img {
        height: 120px;
    }

    .lower-one p {
        width: auto;
        font-size: 14px; 
    }
}

@media (max-width: 480px) {
    .lower-exp {
        padding: 10px; 
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .upper-exp h1 {
        font-size: 20px; 
        padding: 15px;
    }

    .upper-exp p {
        font-size: 14px; 
        padding: 5px;
    }

    .lower-one {
        flex-direction: row;
        justify-content: center; 
        gap: 10px; 
    }

    .lower-one img {
        width: 100px; 
        height: 100px; 
    }
}
