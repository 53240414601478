.contentes {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.minimized-news .neews.minimized .contentes a {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-left: 0px;
    color: rgba(164, 136, 46, 0.7);
    font-size: larger;
    font-weight: bolder;
}

.newsD img {
    height: 470px;
    width: auto; 
}

.newsD button {
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100px;
}

.newsD h3 {
    margin: 15px;
    margin-top: 24px;
    margin-left: 0px;
    color: rgba(0, 128, 0, 0.7);
}

.TNews .minimized-news {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 0px;
    margin-left: 30px;
    width: 100%;
}

.newsD {
    width: 500px;
}


.minimized-news h2 {
    color: rgba(0, 128, 0, 0.7);
    /* margin-bottom: 20px; */
}


.tserv {
    display: flex;
    flex-direction: row;
}

.im-info {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    margin-top: 10px;
}

.im-info img {
    height: 300px;
    width: 300px;
}

.im-info .em a {
    color: rgba(0, 128, 0, 0.7);
}

.lower-ppp {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.lower-ppp h2 {
    color: rgba(0, 128, 0, 0.7);
    /* margin-bottom: 20px; */
}

.lower-ppp li {
    font-weight: small;
}

.news.minimized {
    width: 200px;
    scroll-behavior: smooth;
}

.minimized-news {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    background-color: white;
    scroll-behavior: smooth;
}

@keyframes appear {
    from {
        opacity: 0;
        scale: 0.5;
    }
    to {
        opacity: 1;
        scale: 1;
    }
}

.lower-ppp {
    animation: appear 5s linear;
    animation-fill-mode: forwards; 
}



.TNews{
    display: flex;
    flex-direction: column;
    
}
.minimized-service{
    display: flex;
    flex-direction: column;
    height: auto;
    padding-left: 100px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.tserv{
    display: flex;
    flex-direction: row;
    gap: 40px;
    
}
.upper-ppp img{
   width: 1000px;
}

.minimized-service {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto; 
}

/* .news {
    margin: 10px 0; 
} */
.upper-ppp {
    display: flex; 
    justify-content: center; 
    align-items: center; 
    padding: 10px; 
}

.upper-ppp img {
    max-width: 100%; 
    height: auto; 
    border-radius: 8px;
}

@media (max-width: 1024px) {
    /* .TNews{
        width: auto;
    } */
    .tserv{
        display :flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .minimized-news{
        display :flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .newsD {
        width: 100%; 
    }

    .im-info img {
        height: 200px;
        width: 200px; 
    }

    .minimized-news {
        width: 100%; 
    }
    .upper-ppp img{
        width: 400px;
    }
}

@media (max-width: 768px) {
    /* .TNews{
        width: auto;
    } */
    .tserv{
        display :flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .minimized-news{
        display :flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .newsD img {
        height: 300px; 
    }

    .lower-ppp {
        gap: 15px; 
    }

    .minimized-news {
        width: 100%; 
    }
    .contentes{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 50px;
    }
    /* .TNews {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    } */
    .news.minimized{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
}

@media (max-width: 480px) {
   
    .tserv{
        display :flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .minimized-news{
        display :flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .newsD img {
        height: 200px; 
    }

    .newsD {
        width: 100%; 
    }

    .im-info {
        flex-direction: column; 
        align-items: center; 
    }

    .im-info img {
        height: 150px;
        width: 150px; 
    }

    .minimized-news {
        width: 100%;
    }
  
}

/* Media query for small devices */
@media (max-width: 600px) {
    .upper-ppp {
        padding: 5px; 
    }

    .upper-ppp img {
        
        max-width: 90%; 
        margin: auto; 
    }
}
.TNews{
    display: flex;
    flex-direction: column;
    padding: 0px;
}