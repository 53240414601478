.amount {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: column;
}
.amount h1{
    color: rgba(7, 7, 197, 0.7);
}
.am11 {
    display: flex;
    flex-direction: row;
    color: rgba(7, 7, 197, 0.7);
    gap: 50px;
}
@media (max-width: 768px) {
    .amount {
        display: flex;
        margin-top: 20px; 
        margin-bottom: 20px; 
        flex-direction: column;
    }

    .am11 {
        gap: 30px; 
    }
}

@media (max-width: 480px) {
    .am11 {
        flex-direction: column; 
        gap: 20px; 
    }
}
@keyframes appear {
    from{
       opacity:0;
       clip-path: inset(100% 100% 0 0);
    }
    to{
       opacity: 1;
       clip-path: inset(0 0 0 0);
    }  
}
.am11{
   animation: appear 5s linear;
   animation-timeline: view();
   animation-range: entry 0% cover 40%;
}